.footer_bg {
    width: 100%;
    height: 44%;
    background-color: #142431;
}

.logo-f {
    width: 3rem;
    margin: 1.5rem;
}

.copy-text {
    margin-top: 6.5rem;
    margin-left: 1.8rem;
    font-size: 12px;
    color: white;
}

.morphine-img {
    width: 35%;
    /* position: absolute; */
    float: right;
    margin-top: 5.5rem;
    margin-bottom: 0px;
    margin-right: -20px;
}

@media screen and (max-width:639px) {
    .footer_bg {
        width: 100%;
        height: 50%;
        background-color: #142431;
    }

    .logo-f {
        width: 3rem;
        margin: 1rem 1rem 0.5rem 0.8rem;
    }

    .copy-text {
        margin-top: 3rem;
        margin-left: 1rem;
        font-size: 12px;
        color: white;
    }

    .morphine-img {
        width: 20%;
        /* position: absolute; */
        float: right;
        margin-top: 0px;
        margin-bottom: 2vh;
        margin-right: -50px;
    }
}

@media screen and (max-device-width: 741px) and (orientation: landscape) {
    .morphine-img {
        width: 20%;
        float: right;
        margin-top: 5.3rem;
        margin-bottom: 0px;
        margin-right: -20px;
    }
}