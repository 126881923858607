body {
  font-family: Poppins;
}

.error {
  color: red;
  font-size: 9px;
  height: 0px;
  float: left;
}

.tableFixHead {
  overflow-y: auto;
  height: 78vh;
}

.bg_image {
  min-height: 310px;
  margin-left: -10px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../src/images/bg-image.svg');
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  text-align: left;
}

table {
  border-collapse: separate;
  width: 100%;
  padding: 0 4px;
}

th,
td {
  padding: 8px 16px;
  border: none;
  font-size: 14px;
}

.tr {
  border-radius: 35px;
  border: none;
  box-shadow: 2px 2px 4px #a8a8a8,
    -3px -3px 6px #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.tableFixHead th {
  background: #000;
  color: white;
}

.green {
  background: #F6F5FF;
}

.white {
  background: white;
}

.dropdwon:focus {
  background-color: black !important;
  outline: 2px solid transparent;
  outline-offset: 0;
  border: none;
}

.dropdown-toggle::after {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.lp-text-size {
  font-size: 2.25rem;
  line-height: 60px;
}

.margin-lr {
  margin-left: 0.5rem;
  margin-right: 3rem
}

.loading-animation {
  animation: loadingAnimation 1s ease-in-out;
}

@keyframes loadingAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.box-shadow {
  /* background: #c5d6f7; */
  border: 1px solid #6c6c6c;
  box-shadow: 2px 2px 4px #a8a8a8,
    -3px -3px 6px #ffffff;
}

.rec-container {
  position: relative;
  width: 200px;
  /* Set the width of your rectangular box */
  height: 100px;
  /* Set the height of your rectangular box */
  /* background:  #c5d6f7; */
  box-shadow: 2px 2px 4px #a8a8a8,
    -3px -3px 6px #ffffff;
}

.circle-left {
  position: absolute;
  border: 1px solid #6c6c6c;
  left: 0%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10vh;
  height: 10vh;
  border-radius: 50%;
}

.circle-right {
  position: absolute;
  border: 1px solid #6c6c6c;
  right: -22%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10vh;
  height: 10vh;
  border-radius: 50%;
}

.clients-cir {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 9rem;
  height: 9rem;
  background-color: #eaeae1;
  border: 3px solid #142431;
  /* background-color: #142431; */
  border-radius: 50%;
}

.company-logos {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.clients-inner-cir {
  width: 5rem;
  height: 5rem;
}

.clients-cir-text {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 9rem;
  height: 9rem;
  font-weight: 600;
  font-size: 20px;
  color: rgb(249, 250, 251, 1);
  background-color: #eaeae1;
  border-radius: 50%;
}

.features-box {
  display: flex;
  position: relative;
  border: 1px solid #6c6c6c;
  text-align: center;
  width: 100%;
  min-height: 28vh;
  padding: 10px;
  font-size: 20px;
  border-radius: 20px;
  box-shadow: 2px 2px 4px #a8a8a8,
    -3px -3px 6px #ffffff;
}

.feddback_card {
  width: 100%;
  position: relative;
}

.feed-ratings {
  padding-left: 15%;
  width: 100%;
  max-width: 100%;
  text-align: left;
  vertical-align: middle;
}

.rating-v-align {
  margin-top: 1rem;
}

.avgRatingCount {
  position: absolute;
  font-size: 25px;
  font-weight: 600;
  top: 1.9rem;
  left: 25%;
}

.milestone-img-art {
  width: 90%;
}

.listen-T {
  margin-top: 1rem;
}

.latest-ratings {
  font-size: 20px;
  color: #000;
  font-weight: 600;
}

.visit-btn {
  border: #142431;
  background: #142431 !important;
  width: auto;
  height: 3rem;
  padding: auto;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  color: white;
  display: flex;
  white-space: nowrap;
  text-align: center;
}

.visit-btn:hover {
  border: #142431;
  background: #142431 !important;
  width: auto;
  height: 3rem;
  padding: auto;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  color: white;
  display: flex;
  white-space: nowrap;
  text-align: center;
}

.flip-lr {
  margin-left: 2%;
  margin-right: 4%;
}

.flip-lr-2 {
  margin-left: 20%;
  margin-right: 20%;
}

.team-role {
  display: column;
  /* margin-left: auto;
  margin-right: auto; */
}

.flip-box {
  background-color: transparent;
  width: 130px;
  height: 130px;
  perspective: 1000px;
  border-radius: 50%;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(-180deg);
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  background-color: #fff;
  border-radius: 50%;
  color: black;
  width: 130px;
  height: 130px;
}

.offer-back-heading {
  font-size: 14px;
}

.flip-box-back {
  background-color: white;
  color: black;
  border: 4px solid #FF942C;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  transform: rotateY(180deg);
}

.offer-each-icon {
  width: 130px;
  border-radius: 50%;
  margin-left: 0%;
}

.features-box-1 {
  width: 80%;
  margin: 10px;
  min-height: 50vh;
  max-height: 57vh;
  padding: 20px;
  border-style: solid;
  border-radius: 20px;
  box-shadow: 2px 2px 4px #a8a8a8,
    -3px -3px 6px #ffffff;
}

.background-color {
  background-color: aqua;
}

.shadow {
  box-shadow: 2px 2px 3px #a8a8a8,
    -3px -3px 5px #ffffff;
}

.whitespace {
  white-space: normal;
}

.text-align-left {
  padding: 0 0.5rem 0 3rem;
}

.simple-img {
  width: 75%;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.image {
  width: 75%;
  background-color: white;
  padding: 10px;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 2px 2px 4px #a8a8a8,
    -3px -3px 6px #ffffff;
}

.directory-img {
  padding: 10px;
  /* border-top-left-radius: 30px;
  border-bottom-right-radius: 30px; */
  box-shadow: 2px 2px 4px #a8a8a8,
    -3px -3px 6px #ffffff;
}

.offer-text {
  font-size: 14px;
  font-weight: 600;
}

.img-height {
  height: 45vh;
}

.text-height {
  width: 100%;
  margin: auto;
  height: 45vh;
  padding: 20px;
}

.showTags {
  display: none;
}

.sec-display {
  display: flex;
  grid-auto-rows: 4;
  gap: 3vw;
  align-items: center;
  text-align: center;
  justify-content: center;
}

@keyframes fill {
  0% {
    width: 0%;
    background-color: #FF942C !important;
  }

  100% {
    width: 100%;
    background-color: #FF942C !important;
  }
}

.bg-color {
  margin-top: -0.1px;
  background-color: #FF942C !important;
  animation: fill 10s linear 1;
  height: 100%;
  cursor: pointer;
}

.sec-color {
  width: 70px;
  height: 8px;
  background-color: gray;
  cursor: pointer;
}

.help-img {
  padding: 10px;
  box-shadow: 2px 2px 4px #a8a8a8,
    -3px -3px 6px #ffffff;
}

@media screen and (max-width:639px) {
  .showTags {
    display: block;
  }

  .bg_image {
    margin-left: 5px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .lp-text-size {
    font-size: 1.6rem;
    line-height: 40px;
  }

  .margin-lr {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .clients-cir {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 3rem;
    height: 3rem;
    background-color: #eaeae1;
    border: 3px solid #142431;
    border-radius: 50%;
  }

  .clients-inner-cir {
    width: 2rem;
    height: 2rem;
  }

  .circle-left {
    position: absolute;
    border: 1px solid #6c6c6c;
    left: 0%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 7vh;
    height: 7vh;
    border-radius: 50%;
  }

  .circle-right {
    position: absolute;
    border: 1px solid #6c6c6c;
    right: -18%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 7vh;
    height: 7vh;
    border-radius: 50%;
  }

  .clients-cir-text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 3rem;
    height: 3rem;
    font-weight: 600;
    font-size: 12px;
    color: rgb(249, 250, 251, 1);
    background-color: #eaeae1;
    border: 3px solid #142431;
    border-radius: 50%;
  }

  .features-box {
    display: flex;
    position: relative;
    border: 1px solid #6c6c6c;
    text-align: center;
    width: 80%;
    min-height: 28vh;
    padding: 0px;
    font-size: 14px;
    border-radius: 20px;
    box-shadow: 2px 2px 4px #a8a8a8,
      -3px -3px 6px #ffffff;
  }

  .feddback_card {
    width: 100%;
  }

  .feed-ratings {
    padding-left: 10%;
    width: 100%;
    max-width: 100%;
    text-align: left;
  }

  .rating-v-align {
    margin-top: 2.2rem;
  }

  .avgRatingCount {
    position: absolute;
    font-size: 20px;
    font-weight: 600;
    top: 2.6rem;
    left: 20%;
  }

  .listen-T {
    margin-top: 0px;
  }

  .latest-ratings {
    font-size: 18px;
    color: #000;
    font-weight: 600;
  }

  .flip-lr {
    margin: auto;
  }

  .flip-lr-2 {
    margin: auto;
    margin-left: 6%;
  }

  .team-role {
    align-items: center;
    justify-content: center;
  }

  .flip-box {
    background-color: transparent;
    width: 100px;
    height: 100px;
    perspective: 1000px;
    border-radius: 50%;
  }

  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .flip-box:hover .flip-box-inner {
    transform: rotateY(-180deg);
  }

  .flip-box-front,
  .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-box-front {
    background-color: #fff;
    color: black;
    width: 100px;
    height: 100px;
  }

  .offer-back-heading {
    font-size: 11px;
  }

  .flip-box-back {
    background-color: white;
    color: black;
    border: 3px solid #FF942C;
    font-size: 9px;
    font-family: Open Sans;
    width: 100px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    height: 100px;
    border-radius: 50%;
    transform: rotateY(180deg);
  }

  .offer-each-icon {
    width: 100px;
    margin: auto;
    display: inline;
  }

  .features-box-1 {
    /* display: flex;
    position: relative; */
    /* border: 1px solid #6c6c6c;
    text-align: center;
    width:80%;
    min-height:28vh;
    padding:10px;
    margin:5px 1rem;
    font-size:14px;
    border-radius: 20px;
    box-shadow: 2px 2px 4px #a8a8a8,
    -3px -3px 6px #ffffff; */
    width: 94%;
    margin: auto;
    min-height: 64vh;
    max-height: 75vh;
    padding: 10px;
    border-style: solid;
    border-radius: 20px;
    box-shadow: 2px 2px 4px #a8a8a8,
      -3px -3px 6px #ffffff;
  }

  .image {
    width: 75%;
    background-color: white;
    padding: 10px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    /* display: flex;
  justify-content: center;
  align-items: center; */
    box-shadow: 2px 2px 4px #a8a8a8,
      -3px -3px 6px #ffffff;
  }

  .simple-img {
    width: 80%;
    margin-top: 5vh;
    margin-right: 0;
  }

  .offer-text {
    font-size: 14px;
    font-weight: 600;
  }

  .whitespace {
    white-space: normal;
    display: block;
  }

  .text-align-left {
    padding: 0 10px 0 20px;
  }

  .img-height {
    height: 25vh;
  }

  .text-height {
    width: 100%;
    margin: auto;
    height: 25vh;
    padding: 20px;
  }
}

@media screen and (max-device-width: 900px) and (orientation: landscape) {
  .flip-lr {
    margin-left: 2%;
    margin-right: 4%;
  }

  .team-role {
    display: column;
    /* margin-left: auto;
        margin-right: auto; */
  }

  .flip-box {
    background-color: transparent;
    width: 120px;
    height: 120px;
    perspective: 1000px;
    border-radius: 50%;
  }

  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .flip-box:hover .flip-box-inner {
    transform: rotateY(-180deg);
  }

  .flip-box-front,
  .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-box-front {
    background-color: #fff;
    color: black;
    width: 120px;
    height: 120px;
  }

  .offer-back-heading {
    font-size: 14px;
  }

  .flip-box-back {
    background-color: white;
    color: black;
    border: 4px solid #FF942C;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    transform: rotateY(180deg);
  }

  .offer-each-icon {
    width: 120px;
    margin-left: 0%;
  }

  .clients-cir {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 9rem;
    height: 9rem;
    background-color: #eaeae1;
    border: 3px solid #142431;
    border-radius: 50%;
  }

  .clients-inner-cir {
    width: 5rem;
    height: 5rem;
  }

  .clients-cir-text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 9rem;
    height: 9rem;
    font-weight: 600;
    font-size: 20px;
    color: rgb(249, 250, 251, 1);
    background-color: #142431;
    border-radius: 50%;
  }

  .features-box-1 {
    width: 95%;
    margin: 10px;
    min-height: 100vh;
    max-height: 100vh;
    padding: 20px;
    border-style: solid;
    border-radius: 20px;
    box-shadow: 2px 2px 4px #a8a8a8,
      -3px -3px 6px #ffffff;
  }

  .whitespace {
    white-space: nowrap;
  }

  .text-align-left {
    padding: 0 0.8rem 0 1.5rem;
  }

  .simple-img {
    width: 60%;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  .image {
    width: 60%;
    background-color: white;
    padding: 10px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 2px 2px 4px #a8a8a8,
      -3px -3px 6px #ffffff;
  }

  .offer-text {
    font-size: 16px;
    font-weight: 600;
  }

  .img-height {
    max-height: 20vh;
  }

  .showTags {
    display: block;
  }
}