.terms-heading {
    margin-top: 3vh;
    margin-left: 1.6rem;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.8px;
    color: #1E2022;
  }
  .terms_Text {
    font-size: 15px;
    line-height: 25px;
    margin-top: 5px;
    font-family: Poppins;
    font-style: normal;
  }
  .heading {
    font-size: 20px;
    font-weight:600;
    margin-top:1rem;
  }
  .wrap_ {
    display: flex;
    background: transparent;
    width: 100%;
    justify-content: space-between;
  }
  .wrap_ div:last-child {
    margin-left: auto;
    padding: 0.5rem;
  }

  