.react-tags__combobox-input {
    width: 20vw !important;
    height: 40px;
    border: 2px solid gray;
    border-radius: 10px;
    padding: 2px 10px;
}

.showTags {
    display: none;
}

@media (min-width: 769px) {
    .showTags {
        display: block;
    }
}

.icon-w {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    box-shadow: 2px 2px 4px #a8a8a8,
        -3px -3px 6px #ffffff;
    ;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    color: #eff2fe;
    transition: all .5s ease;
}